(function($) {
  // Load Events
  $(document).ready();

  var enFlag = $('.nav [title="English"]');
  var nlFlag = $('.nav [title="Nederlands"]');
  var deFlag = $('.nav [title="Deutsch"]');
  var esFlag = $('.nav [title="Español"]');
  var frFlag = $('.nav [title="Français"]');

  $($(enFlag)[0]).attr("src", "wp-content/themes/knowler/dist/images/flags/gb.png");
  $($(nlFlag)[0]).attr("src", "wp-content/themes/knowler/dist/images/flags/nl.png");
  $($(deFlag)[0]).attr("src", "wp-content/themes/knowler/dist/images/flags/de.png");
  $($(esFlag)[0]).attr("src", "wp-content/themes/knowler/dist/images/flags/es.png");
  $($(frFlag)[0]).attr("src", "wp-content/themes/knowler/dist/images/flags/fr.png");

  if ($($(enFlag)[1])) {
    $($(enFlag)[1]).attr("src", "wp-content/themes/knowler/dist/images/flags/gb.png");
  }
  if ($($(nlFlag)[1])) {
    $($(nlFlag)[1]).attr("src", "wp-content/themes/knowler/dist/images/flags/nl.png");
  }
  if ($($(deFlag)[1])) {
    $($(deFlag)[1]).attr("src", "wp-content/themes/knowler/dist/images/flags/de.png");
  }
  if ($($(esFlag)[1])) {
    $($(esFlag)[1]).attr("src", "wp-content/themes/knowler/dist/images/flags/es.png");
  }
  if ($($(frFlag)[1])) {
    $($(frFlag)[1]).attr("src", "wp-content/themes/knowler/dist/images/flags/fr.png");
  }

  // Scroll to with space for empty fields on sign up and similar pages
  if ($('.subscribe_section').is('div')) {
    var invalidDelay = 0;
    var invalidOffset = 150;

    document.addEventListener('invalid', function (e) {
      $(e.target).addClass("invalid");
      $('html, body').animate({scrollTop: $($(".invalid")[0]).offset().top - invalidOffset}, invalidDelay);
    }, true);
    document.addEventListener('change', function (e) {
      $(e.target).removeClass("invalid")
    }, true);
  }

  var windowWidth = $(window).innerWidth();

  $(window).resize(function () {
    windowWidth = $(window).innerWidth();
  });

  var backToTopBtn = $('.back-to-top');

  // Scroll to top btn
  backToTopBtn.on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({scrollTop: 0}, 500);
  });

  /// make video 56% height of the width
  var videos = $('video');

  function setVideoParams() {
    videos.each(function () {
      var thisVideo = $(this);
      thisVideo.css('height', thisVideo.width() * 0.55625);
    });
  }

  if (videos) {
    setVideoParams();

    $(window).resize(function () {
      setVideoParams()
    });
  }


  // Dropdown menu
  var dropdownItem = $('header .menu-item-has-children');
  dropDownFnc(windowWidth);

  $(window).resize(function () {
    dropDownFnc(windowWidth);

    dropdownItem.removeClass('mob-menu-open');
  });

  function dropDownFnc(windowWidth) {
    if (windowWidth > 991) {
      dropdownItem.mouseover(function () {
        var $this = $(this);
        var subMenu = $('.sub-menu');

        $this.addClass('visible');

        $this.find(subMenu).addClass('show-dropdown');
      });

      dropdownItem.mouseleave(function () {
        dropdownItem.removeClass('visible');

        var $this = $(this);
        var subMenu = $('.sub-menu');

        $this.find(subMenu).removeClass('show-dropdown');
      });
    } else {
      var dropdownItemMob = $('.menu-item-has-children > a');

      dropdownItemMob.on('click', function (e) {
        // if (e.offsetX > this.offsetWidth) {
        e.preventDefault();

        var checkHeight = 25;
        var parent = $(e.target).closest('.menu-item-has-children'),
            parentHeight = $(parent).innerHeight();

        if (parentHeight > checkHeight) {
          if (dropdownItemMob.closest('.extra-nav-menu').find('.extra-sub-menu-internal')) {
            document.location.href = $(this).attr('href');
          }
          dropdownItem.height(checkHeight).removeClass('mob-menu-open');
        } else {
          dropdownItem.height(checkHeight).removeClass('mob-menu-open');

          var subMenuHeight = $(parent).find('.sub-menu').innerHeight();
          $(parent).height(checkHeight + subMenuHeight).addClass('mob-menu-open');
        }
        // }
      });
    }
  }

  //===== Testimonials section slider
  if ($('.section.testimonials').is('div')) {

    var testimonialsSelector = '.testimonials-slider .swiper-container';

    var testimonialsSlidesPerView = 3;
    var testimonialsBreakpoints = {
      767: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      1400: {
        slidesPerView: 2,
        spaceBetween: 25
      }
    };

    if ($('.testimonials-slider .slider-container').hasClass('testimonials_num-1')) {

      testimonialsSlidesPerView = 1;

      testimonialsBreakpoints = {
        767: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        1400: {
          slidesPerView: 1,
          spaceBetween: 25
        }
      }
    }

    var TestimonialsSwiper = new Swiper(testimonialsSelector, {
      // Optional parameters
      direction: 'horizontal',
      slidesPerView: testimonialsSlidesPerView,
      slidesPerColumn: 1,
      slidesOffsetAfter: 0,
      spaceBetween: 20,
      loop: false,
      roundLengths: true,
      speed: 700,
      autoplay: true,
      breakpoints: testimonialsBreakpoints,
      pagination: {
        el: '.testimonials-pagination',
        type: 'bullets',
        bulletElement: 'span',
        clickable: true
      }
    });
  }

  //===== slider-in-slider ( Home main section )
  if ($('.slider-in-slider').is('div')) {
    var sliderInSliderSwiper = new Swiper($('.slider-in-slider .swiper-container'), {
      // Optional parameters
      direction: 'horizontal',
      slidesPerView: 1,
      slidesPerColumn: 1,
      slidesOffsetAfter: 0,
      spaceBetween: 15,
      loop: false,
      roundLengths: true,
      speed: 700,
      autoplay: false,
      pagination: {
        el: '.slider-in-slider-pagination',
        type: 'bullets',
        bulletElement: 'span',
        clickable: true
      }
    });

    sliderInSliderSwiper.update();
  }

  var pureImagesSwiper = $('.pure-images-swiper');

  if (pureImagesSwiper.is('div')) {
    var pureSlider = new Swiper(pureImagesSwiper, {
      // Optional parameters
      direction: 'horizontal',
      slidesPerView: 1,
      slidesPerColumn: 1,
      slidesOffsetAfter: 0,
      spaceBetween: 40,
      loop: false,
      roundLengths: true,
      speed: 700,
      autoplay: false,
      breakpoints: {
        767: {
          spaceBetween: 15
        },
        992: {
          spaceBetween: 25
        }
      }
    });
  }


  $('.toggle-btn.btn-control-js').on('click', function (e) {
    e.preventDefault();
    history.pushState(null, null, $(this).data('target'));

    $('.collapseSlide').removeClass('show');
    $($(this).data('target')).addClass('show');

    $('.toggle-btn.btn-control-js').attr('aria-expanded', 'false');
    $(this).attr('aria-expanded', 'true');

    sliderInSliderSwiper.update();
  });

  //===  Pricing Count logick  ===
  function countTotalPrice() {
    var totalPriceBlocks = $('.users-count-price');

    var count = 0;

    totalPriceBlocks.each(function () {
      count += Number($(this).text().replace('€', '').replace(',-', ''));
    });

    $('#total-price-text').text('€' + count + ',-');
  }

  function showActiveAddonsTitles() {
    var activeTitlesText = '';

    $('.add_on_feature.focused').each(function () {
      var activeAddOnTitle = $(this).find('.feature-title').text();
      activeTitlesText += '<p class="small-title" data-feature-code="' + $(this).attr('data-feature-code') + '">' + activeAddOnTitle + '</p>'
    });

    $('#active-titles').html(activeTitlesText);
  }


  function calculateAddonsPrices() {
    var count = 0;

    $('.add_on_feature.focused').each(function () {
      var activeAddOnpriceText = $(this).find('.price-num').text();

      if (activeAddOnpriceText.includes('€')) {
        var activeAddOnpriceNum = Number(activeAddOnpriceText.replace('€', '').replace(',-', ''));

        if ($(this).hasClass('per_user')) {
          var currentUsersNum = Number($('#adjustments-users-number').html());

          if (!isNaN(activeAddOnpriceNum)) {
            activeAddOnpriceNum = activeAddOnpriceNum * currentUsersNum;
          }
        }

        if (!isNaN(activeAddOnpriceNum)) {
          count += activeAddOnpriceNum;
        }
      }
    });

    $('#add-ons-total-price').text('€' + count + ',-')
  }

  $('#adjustments-users-plus').on('click', function (e) {
    e.preventDefault();

    var adjustmentsUsersNumber = $('#adjustments-users-number');

    var currentNum = Number(adjustmentsUsersNumber.html());

    adjustmentsUsersNumber.text(1 + currentNum);

    $('#adjustments-users-money-count').text('€' + (currentNum + 1) * 49 + ',-');
    $('#total-price-block-users-price').text('€' + (currentNum + 1) * 49 + ',-');

    $('#total-price-block-users-number').text(currentNum + 1);

    calculateAddonsPrices();
    countTotalPrice();
  });

  $('#adjustments-users-minus').on('click', function (e) {
    e.preventDefault();

    var adjustmentsUsersNumber = $('#adjustments-users-number');

    var currentNum = Number(adjustmentsUsersNumber.html());

    if (currentNum > 1) {
      adjustmentsUsersNumber.text(currentNum - 1);

      $('#adjustments-users-money-count').text('€' + (currentNum - 1) * 49 + ',-');
      $('#total-price-block-users-price').text('€' + (currentNum - 1) * 49 + ',-');

      $('#total-price-block-users-number').text(currentNum - 1);

      calculateAddonsPrices();
      countTotalPrice();
    }
  });


  /*
  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  */

  //startDirectlyBtn create url and open in new tab
  $('#startDirectlyBtn').on('click', function (e) {
    e.preventDefault();

    var url = $('#startDirectlyBtn').attr('data-directly_url');

    url += url.includes('?') ? '&' : '?';
    url += 'users=' + $('#total-price-block-users-number').text();
    url += '&features=';

    var addons = '';
    var addonsItems = $('#active-titles .small-title');
    var addonsItemsLength = addonsItems.length;

    addonsItems.each(function (idx) {
      var addDivider = ',';

      if (idx === addonsItemsLength - 1) {
        addDivider = ''
      }

      addons += $(this).attr('data-feature-code').trim() + addDivider;
    });

    url += addons ? addons : '0';

    url += '&lang=' + $('html').attr('lang');

    // window.open(url, "_blank");
    window.open(url, "_self");
  });


  //
  $('a.check-indicator').on('click', function (e) {
    e.preventDefault();

    $(this).toggleClass('checked');
    $($(this).closest('.add_on_feature')).toggleClass('focused');


    //===
    var featureTitle = $($(this).closest('.add_on_feature')).find('.feature-title');
    var dataAddonClass = $(featureTitle).attr('data-addon-class');
    var className = dataAddonClass.replace('.', '');

    $('#add-ons-block').toggleClass(className);

    //===
    showActiveAddonsTitles();
    calculateAddonsPrices();

    countTotalPrice();

  });


  //===  home slider  ===
  function collapseSlideHeight() {
    var height = 100;

    $('.collapseSlide').each(function () {
      if ($(this).innerHeight() > height) {
        height = $(this).innerHeight();
      }
    });

    $('#home-accordion-slider .slides').innerHeight(height);
  }

  $(window).resize(function () {
    if ($('#home-accordion-slider').is('div')) {
      collapseSlideHeight();
    }
  });


  $(window).on('load', function () {

    // todo: remove after banner functional approve
    function isBannerPage() {
      var pagePathName = window.location.pathname.split("/");

      return pagePathName[pagePathName.length - 1] === "bannertest" || pagePathName[pagePathName.length - 2] === "bannertest";
    }

    //===  show random modal banner  ===
    function runModal() {
      var modalWasClosed = localStorage.getItem('modalWasClosed');

      if (!modalWasClosed && isBannerPage()) {
        var modalBannerItems = [];

        $('.modalBanner').each(function () {
          var id = $(this).attr('id');
          var idSelector = '#' + id;

          modalBannerItems.push(idSelector);
        });

        var randomModalItem = modalBannerItems[Math.floor(Math.random() * modalBannerItems.length)];

        $(randomModalItem).modal('show');

        localStorage.setItem('modalWasClosed', true);
      }
    }

    setTimeout(runModal, 1000);

    var homeAccordionSlider = $('#home-accordion-slider');

    if (homeAccordionSlider.is('div')) {
      collapseSlideHeight();

      function isContainsStr(str, substrings) {
        for (var i = 0; i < substrings.length; i++) {
          var substring = substrings[i];
          if (str === substring) {
            return true;
          }
        }
        return false;
      }

      var urlHash = window.location.hash;

      var buttonControls = homeAccordionSlider.find('.buttons-controls .btn-control-js');

      var buttonControlsHashes = [];

      buttonControls.each(function () {
        buttonControlsHashes.push($(this).attr('data-target'));
      });

      if (urlHash && isContainsStr(urlHash, buttonControlsHashes)) {
        window.setTimeout(function () {
          $(window).scrollTop(0);
        }, 0);

        if (urlHash === $(buttonControls[0]).attr('data-target')) {
          return;
        }

        var queryTarget = $('body').find(urlHash);
        var slideBlock = $(queryTarget[0]);

        $('.collapseSlide').removeClass('show');
        slideBlock.addClass('show');

        $('.toggle-btn.btn-control-js').attr('aria-expanded', 'false');
        $(slideBlock.data('btn-id')).attr('aria-expanded', 'true');

        if (sliderInSliderSwiper) {
          sliderInSliderSwiper.update();
        }
      }
    }

    // scroll sidebar

    var totalPriceBlock = $('.total-price-block');

    if (totalPriceBlock.is('div') && windowWidth > 991) {
      var sidebarHeight = totalPriceBlock.outerHeight();
      var adjustmentsHeight = $('.plan-adjustments').outerHeight();
      var sidebarOffset = totalPriceBlock.offset();

      var $sidebar = totalPriceBlock,
          $window = $(window),
          offset = sidebarOffset,
          topPadding = $('.banner .header').outerHeight() + 15;


      $('a.check-indicator').on('click', function (e) {
        e.preventDefault();

        sidebarHeight = totalPriceBlock.outerHeight();
      });

      function positionTotalPrice(topPadding, offset, $window, $sidebar) {
        if (($(window).scrollTop() + topPadding) > offset.top) {
          var mTop = $window.scrollTop() + topPadding - offset.top;

          if (mTop + sidebarHeight > adjustmentsHeight) {
            mTop = adjustmentsHeight - sidebarHeight;
          }

          $sidebar.animate({
            marginTop: mTop
          }, {
            duration: 0
          });

        } else {
          $sidebar.animate({
            marginTop: 0
          }, {
            duration: 0
          });
        }
      }

      /*$(window).resize(function() {
        sidebarHeight     = $(".total-price-block").outerHeight();
        adjustmentsHeight = $('.plan-adjustments').outerHeight();
        sidebarOffset     = $(".total-price-block").offset();

        offset            = sidebarOffset;
        topPadding        = $('.banner .header').outerHeight() + 15;

        positionTotalPrice(topPadding, offset, $window, $sidebar );
      });*/

      $(window).scroll(function () {
        positionTotalPrice(topPadding, offset, $window, $sidebar);
      });
    }

    /* iframe settings */
    $('.iframe-wrapper').each(function () {
      var thisFrame = $(this);
      thisFrame.css('padding-bottom', thisFrame.width() * 0.5625);
    });

    $(window).resize(function () {
      $('.iframe-wrapper').each(function () {
        var thisFrame = $(this);
        thisFrame.css('padding-bottom', thisFrame.width() * 0.5625);
      });
    });

    /* Prevent closing opened tab */
    $('.simple-features .head-buttons [data-toggle="collapse"]').on('click', function (e) {
      var ariaExpanded = $(this).attr('aria-expanded');

      if (ariaExpanded === 'true') {
        e.stopPropagation();
        e.preventDefault();
      }
    });

    //=====  Ajax loader
    if ($('.index-posts-wrap').is('div')) {
      $(document).on('click', '.posts-permalink-more', function (e) {
        e.preventDefault();

        var fullLenght = Number($('.index-posts-wrap').attr('data-lenght'));
        var currentCountItemsOnPage = $('.post-item-block').length;

        if (fullLenght <= (currentCountItemsOnPage + 6)) {
          $(this).addClass('d-none');
        }

        $.ajax({
          url: ajaxURL.ajaxurl,
          type: "POST",
          data: {
            'action': 'ajax_load_more',
            'itemsOnPage': currentCountItemsOnPage
          },
          success: function (data, textStatus, jqXHR) {
            var postsBlock = $('.index-posts-wrap');
            postsBlock.append(data);
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR, textStatus, errorThrown, '2');
          }
        });
      });


      $(document).on('click', '.category-permalink-more', function (e) {
        e.preventDefault();

        var fullLenght = Number($('.index-posts-wrap').attr('data-lenght'));
        var currentCountItemsOnPage = $('.post-item-block').length;

        var currentCategory = $('.category-permalink-more').attr('data-category');

        if (fullLenght <= (currentCountItemsOnPage + 6)) {
          $(this).addClass('d-none');
        }

        $.ajax({
          url: ajaxURL.ajaxurl,
          type: "POST",
          data: {
            'action': 'ajax_load_more_category',
            'itemsOnPage': currentCountItemsOnPage,
            'category': currentCategory
          },
          success: function (data, textStatus, jqXHR) {
            var postsBlock = $('.index-posts-wrap');
            postsBlock.append(data);
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR, textStatus, errorThrown, '2');
          }
        });
      });
    }
  });

  /* Prevent closing opened tab */
  $('.client-groups-buttons [data-toggle="collapse"]').on('click', function (e) {
    var ariaExpanded = $(this).attr('aria-expanded');

    if (ariaExpanded === 'true') {
      e.stopPropagation();
      e.preventDefault();
    }
  });

  $('.anchor-link').click(function (e) {
    e.preventDefault();
    var aid = $(this).attr('href');

    $('html,body').animate({scrollTop: $(aid).offset().top - 100}, 500);
  });

  //Check to see if the window is top if not then display button

  $(window).scroll(function(){
    var showAfter = 800;
    if ($(this).scrollTop() > showAfter ) {
      $('.back-to-top').fadeIn();
    } else {
      $('.back-to-top').fadeOut();
    }
  });

  //Click event to scroll to top
  $('.back-to-top').click(function(){
    $('html, body').animate({scrollTop : 0 },800);
    return false;
  });


  $('.cb-value').click(function() {
    var mainParent = $(this).parent('.checkbox-blue');
    if($(mainParent).find('input.cb-value').is(':checked')) {
      $(mainParent).addClass('active');
      $('.month').hide().removeClass('anchor');
      $('.year').show().addClass('anchor');
    } else {
      $(mainParent).removeClass('active');
      $('.year').hide().removeClass('anchor');
      $('.month').show().addClass('anchor');
    }
  });

  $('.scrollto').on('change', function(){
    $('html').animate({
          scrollTop: $('.anchor').find('.' + $(this).val()).offset().top
        }, 500
    );
  });

  $(window).resize(function() {
    if ($(window).width() < 768) {
      $('.table-click-js').click(function (){
        $('html, body').animate({
          scrollTop: $('.anchor').find('.' + $(this).attr('name')).offset().top
        }, 2000);
      });
    }
  });


  var gform8 = $('#gform_submit_button_8');
  var gform9 = $('#gform_submit_button_9');
  var gform10 = $('#gform_submit_button_10');
  var gform11 = $('#gform_submit_button_11');
  var gform12 = $('#gform_submit_button_12');
  var gform13 = $('#gform_submit_button_13');
  var gform14 = $('#gform_submit_button_14');
  var gform15 = $('#gform_submit_button_15');
  var gform16 = $('#gform_submit_button_16');
  var gform17 = $('#gform_submit_button_17');
  var gform18 = $('#gform_submit_button_18');
  var gform19 = $('#gform_submit_button_19');
  var gform20 = $('#gform_submit_button_20');

  gform8.click(function() {
    if(window["gf_submitting_8"]){return false;}  window["gf_submitting_8"]=true;
  });

  gform8.keypress(function(event) {
    if( event.keyCode == 13 ){ if(window["gf_submitting_8"]){return false;} window["gf_submitting_8"]=true;
      jQuery("#gform_8").trigger("submit",[true]);
    }
  });

  gform9.click(function() {
    if(window["gf_submitting_9"]){return false;}  window["gf_submitting_9"]=true;
  });

  gform9.keypress(function(event) {
    if( event.keyCode == 13 ){ if(window["gf_submitting_9"]){return false;} window["gf_submitting_9"]=true;
      jQuery("#gform_9").trigger("submit",[true]);
    }
  });

  gform10.click(function() {
    if(window["gf_submitting_10"]){return false;}  window["gf_submitting_10"]=true;
  });

  gform10.keypress(function(event) {
    if( event.keyCode == 13 ){ if(window["gf_submitting_10"]){return false;} window["gf_submitting_10"]=true;
      jQuery("#gform_10").trigger("submit",[true]);
    }
  });

  gform11.click(function() {
    if(window["gf_submitting_11"]){return false;}  window["gf_submitting_11"]=true;
  });

  gform11.keypress(function(event) {
    if( event.keyCode == 13 ){ if(window["gf_submitting_11"]){return false;} window["gf_submitting_11"]=true;
      jQuery("#gform_11").trigger("submit",[true]);
    }
  });

  gform12.click(function() {
    if(window["gf_submitting_12"]){return false;}  window["gf_submitting_12"]=true;
  });

  gform12.keypress(function(event) {
    if( event.keyCode == 13 ){ if(window["gf_submitting_12"]){return false;} window["gf_submitting_12"]=true;
      jQuery("#gform_12").trigger("submit",[true]);
    }
  });

  gform13.click(function() {
    if(window["gf_submitting_13"]){return false;}  window["gf_submitting_13"]=true;
  });

  gform13.keypress(function(event) {
    if( event.keyCode == 13 ){ if(window["gf_submitting_13"]){return false;} window["gf_submitting_13"]=true;
      jQuery("#gform_13").trigger("submit",[true]);
    }
  });

  gform14.click(function() {
    if(window["gf_submitting_14"]){return false;}  window["gf_submitting_14"]=true;
  });

  gform14.keypress(function(event) {
    if( event.keyCode == 13 ){ if(window["gf_submitting_14"]){return false;} window["gf_submitting_14"]=true;
      jQuery("#gform_14").trigger("submit",[true]);
    }
  });

  gform15.click(function() {
    if(window["gf_submitting_15"]){return false;}  window["gf_submitting_15"]=true;
  });

  gform15.keypress(function(event) {
    if( event.keyCode == 13 ){ if(window["gf_submitting_15"]){return false;} window["gf_submitting_15"]=true;
      jQuery("#gform_15").trigger("submit",[true]);
    }
  });

  gform16.click(function() {
    if(window["gf_submitting_16"]){return false;}  window["gf_submitting_16"]=true;
  });

  gform16.keypress(function(event) {
    if( event.keyCode == 13 ){ if(window["gf_submitting_16"]){return false;} window["gf_submitting_16"]=true;
      jQuery("#gform_16").trigger("submit",[true]);
    }
  });

  gform17.click(function() {
    if(window["gf_submitting_17"]){return false;}  window["gf_submitting_17"]=true;
  });

  gform17.keypress(function(event) {
    if( event.keyCode == 13 ){ if(window["gf_submitting_17"]){return false;} window["gf_submitting_17"]=true;
      jQuery("#gform_17").trigger("submit",[true]);
    }
  });

  gform18.click(function() {
    if(window["gf_submitting_18"]){return false;}  window["gf_submitting_18"]=true;
  });

  gform18.keypress(function(event) {
    if( event.keyCode == 13 ){ if(window["gf_submitting_18"]){return false;} window["gf_submitting_18"]=true;
      jQuery("#gform_18").trigger("submit",[true]);
    }
  });

  gform19.click(function() {
    if(window["gf_submitting_19"]){return false;}  window["gf_submitting_19"]=true;
  });

  gform19.keypress(function(event) {
    if( event.keyCode == 13 ){ if(window["gf_submitting_19"]){return false;} window["gf_submitting_19"]=true;
      jQuery("#gform_19").trigger("submit",[true]);
    }
  });

  gform20.click(function() {
    if(window["gf_submitting_20"]){return false;}  window["gf_submitting_20"]=true;
  });

  gform20.keypress(function(event) {
    if( event.keyCode == 13 ){ if(window["gf_submitting_20"]){return false;} window["gf_submitting_20"]=true;
      jQuery("#gform_20").trigger("submit",[true]);
    }
  });


})(jQuery);

var lang = jQuery('html').attr('lang');
switch (lang) {
  case 'de-DE':
    /* <![CDATA[ */
    var gf_global = {"gf_currency_config":{"name":"Euro","symbol_left":"","symbol_right":"&#8364;","symbol_padding":" ","thousand_separator":".","decimal_separator":",","decimals":2},"base_url":"https:\/\/conversation24.de\/wp-content\/plugins\/gravityforms","number_formats":[],"spinnerUrl":"https:\/\/conversation24.de\/wp-content\/plugins\/gravityforms\/images\/spinner.gif"};
    /* ]]> */
    break;
  case 'fr-FR':
    /* <![CDATA[ */
    var ajaxURL = {"ajaxurl":"https:\/\/conversation24.fr\/wp-admin\/admin-ajax.php"};
    /* ]]> */
    break;
  case 'nl-NL':
    /* <![CDATA[ */
    var gf_global = {"gf_currency_config":{"name":"Euro","symbol_left":"","symbol_right":"&#8364;","symbol_padding":" ","thousand_separator":".","decimal_separator":",","decimals":2},"base_url":"https:\/\/conversation24.nl\/wp-content\/plugins\/gravityforms","number_formats":[],"spinnerUrl":"https:\/\/conversation24.nl\/wp-content\/plugins\/gravityforms\/images\/spinner.gif"};
    /* ]]> */
    break;
  case 'es-ES':
    /* <![CDATA[ */
    var Cli_Data = {"nn_cookie_ids":[],"cookielist":[],"non_necessary_cookies":[],"ccpaEnabled":"","ccpaRegionBased":"","ccpaBarEnabled":"","strictlyEnabled":["necessary","obligatoire"],"ccpaType":"gdpr","js_blocking":"1","custom_integration":"","triggerDomRefresh":"","secure_cookies":""};
    var cli_cookiebar_settings = {"animate_speed_hide":"500","animate_speed_show":"500","background":"#FFF","border":"#b1a6a6c2","border_on":"","button_1_button_colour":"#61a229","button_1_button_hover":"#4e8221","button_1_link_colour":"#fff","button_1_as_button":"1","button_1_new_win":"","button_2_button_colour":"#333","button_2_button_hover":"#292929","button_2_link_colour":"#444","button_2_as_button":"","button_2_hidebar":"","button_3_button_colour":"#3566bb","button_3_button_hover":"#2a5296","button_3_link_colour":"#fff","button_3_as_button":"1","button_3_new_win":"","button_4_button_colour":"#000","button_4_button_hover":"#000000","button_4_link_colour":"#333333","button_4_as_button":"","button_7_button_colour":"#61a229","button_7_button_hover":"#4e8221","button_7_link_colour":"#fff","button_7_as_button":"1","button_7_new_win":"","font_family":"inherit","header_fix":"","notify_animate_hide":"1","notify_animate_show":"","notify_div_id":"#cookie-law-info-bar","notify_position_horizontal":"right","notify_position_vertical":"bottom","scroll_close":"","scroll_close_reload":"","accept_close_reload":"","reject_close_reload":"","showagain_tab":"1","showagain_background":"#fff","showagain_border":"#000","showagain_div_id":"#cookie-law-info-again","showagain_x_position":"100px","text":"#333333","show_once_yn":"","show_once":"10000","logging_on":"","as_popup":"","popup_overlay":"1","bar_heading_text":"","cookie_bar_as":"banner","popup_showagain_position":"bottom-right","widget_position":"left"};
    var log_object = {"ajax_url":"https:\/\/conversation24.es\/wp-admin\/admin-ajax.php"};
    /* ]]> */
    /* <![CDATA[ */
    var ajaxURL = {"ajaxurl":"https:\/\/conversation24.es\/wp-admin\/admin-ajax.php"};
    /* ]]> */
    /* <![CDATA[ */
    var gf_global = {"gf_currency_config":{"name":"Euro","symbol_left":"","symbol_right":"&#8364;","symbol_padding":" ","thousand_separator":".","decimal_separator":",","decimals":2},"base_url":"https:\/\/conversation24.es\/wp-content\/plugins\/gravityforms","number_formats":[],"spinnerUrl":"https:\/\/conversation24.es\/wp-content\/plugins\/gravityforms\/images\/spinner.gif"};
    /* ]]> */
    break;
  default:

}


/*

jQuery(document).ready(function($){
  gformInitSpinner( 8, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );

  jQuery('#gform_ajax_frame_8').on('load',function(){
    var contents = jQuery(this).contents().find('*').html();
    var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;if(!is_postback){return;}
    var form_content = jQuery(this).contents().find('#gform_wrapper_8');
    var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_8').length > 0;
    var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
    var is_form = form_content.length > 0 && ! is_redirect && ! is_confirmation;
    var mt = parseInt(jQuery('html').css('margin-top'), 10) + parseInt(jQuery('body').css('margin-top'), 10) + 100;
    if(is_form){jQuery('#gform_wrapper_8').html(form_content.html());
    if(form_content.hasClass('gform_validation_error')){
      jQuery('#gform_wrapper_8').addClass('gform_validation_error');
    } else {
      jQuery('#gform_wrapper_8').removeClass('gform_validation_error');
    }
    setTimeout( function() { /!* delay the scroll by 50 milliseconds to fix a bug in chrome *!/
      jQuery(document).scrollTop(jQuery('#gform_wrapper_8').offset().top - mt);
      }, 50 );
    if(window['gformInitDatepicker']) {
      gformInitDatepicker();
    }
    if (window['gformInitPriceFields']) {
      gformInitPriceFields();
    }
    var current_page = jQuery('#gform_source_page_number_8').val();
    gformInitSpinner( 8, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );
    jQuery(document).trigger('gform_page_loaded', [8, current_page]);
    window['gf_submitting_8'] = false;}
    else if(!is_redirect) {
      var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
      if(!confirmation_content){confirmation_content = contents;}
      setTimeout(function(){
        jQuery('#gform_wrapper_8').replaceWith(confirmation_content);
        jQuery(document).scrollTop(jQuery('#gf_8').offset().top - mt);
        jQuery(document).trigger('gform_confirmation_loaded', [8]);
        window['gf_submitting_8'] = false;
      }, 50);
    } else {
      jQuery('#gform_8').append(contents);
      if(window['gformRedirect']) {gformRedirect();}
    }
    jQuery(document).trigger('gform_post_render', [8, current_page]);
  } );
} );
jQuery(document).bind('gform_post_render', function(event, formId, currentPage){if(formId == 8) {} } );
jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} );
jQuery(document).ready(function(){jQuery(document).trigger('gform_post_render', [8, 1]) } );
*/


if ( jQuery('#gform_wrapper_8').is('div') ) {
  jQuery(document).ready(function($){
    gformInitSpinner( 8, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );

    jQuery('#gform_ajax_frame_8').on('load',function(){
      var contents = jQuery(this).contents().find('*').html();
      var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;if(!is_postback){return;}
      var form_content = jQuery(this).contents().find('#gform_wrapper_8');
      var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_8').length > 0;
      var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
      var is_form = form_content.length > 0 && ! is_redirect && ! is_confirmation;
      var mt = parseInt(jQuery('html').css('margin-top'), 10) + parseInt(jQuery('body').css('margin-top'), 10) + 100;
      if(is_form){jQuery('#gform_wrapper_8').html(form_content.html());
        if(form_content.hasClass('gform_validation_error')){
          jQuery('#gform_wrapper_8').addClass('gform_validation_error');
        } else {
          jQuery('#gform_wrapper_8').removeClass('gform_validation_error');
        }
        setTimeout( function() { /* delay the scroll by 50 milliseconds to fix a bug in chrome */
          jQuery(document).scrollTop(jQuery('#gform_wrapper_8').offset().top - mt);
        }, 50 );
        if(window['gformInitDatepicker']) {
          gformInitDatepicker();
        }
        if (window['gformInitPriceFields']) {
          gformInitPriceFields();
        }
        var current_page = jQuery('#gform_source_page_number_8').val();
        gformInitSpinner( 8, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );
        jQuery(document).trigger('gform_page_loaded', [8, current_page]);
        window['gf_submitting_8'] = false;}
      else if(!is_redirect) {
        var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
        if(!confirmation_content){confirmation_content = contents;}
        setTimeout(function(){
          jQuery('#gform_wrapper_8').replaceWith(confirmation_content);
          jQuery(document).scrollTop(jQuery('#gf_8').offset().top - mt);
          jQuery(document).trigger('gform_confirmation_loaded', [8]);
          window['gf_submitting_8'] = false;
        }, 50);
      } else {
        jQuery('#gform_8').append(contents);
        if(window['gformRedirect']) {gformRedirect();}
      }
      jQuery(document).trigger('gform_post_render', [8, current_page]);
    } );
  } );
  jQuery(document).bind('gform_post_render', function(event, formId, currentPage){if(formId == 8) {} } );
  jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} );
  jQuery(document).ready(function(){jQuery(document).trigger('gform_post_render', [8, 1]) } );
}

if ( jQuery('#gform_wrapper_9').is('div') ) {
  jQuery(document).ready(function($){
    gformInitSpinner( 9, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );

    jQuery('#gform_ajax_frame_9').on('load',function(){
      var contents = jQuery(this).contents().find('*').html();
      var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;if(!is_postback){return;}
      var form_content = jQuery(this).contents().find('#gform_wrapper_9');
      var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_9').length > 0;
      var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
      var is_form = form_content.length > 0 && ! is_redirect && ! is_confirmation;
      var mt = parseInt(jQuery('html').css('margin-top'), 10) + parseInt(jQuery('body').css('margin-top'), 10) + 100;
      if(is_form){jQuery('#gform_wrapper_9').html(form_content.html());
        if(form_content.hasClass('gform_validation_error')){
          jQuery('#gform_wrapper_9').addClass('gform_validation_error');
        } else {
          jQuery('#gform_wrapper_9').removeClass('gform_validation_error');
        }
        setTimeout( function() { /* delay the scroll by 50 milliseconds to fix a bug in chrome */
          jQuery(document).scrollTop(jQuery('#gform_wrapper_9').offset().top - mt);
        }, 50 );
        if(window['gformInitDatepicker']) {
          gformInitDatepicker();
        }
        if (window['gformInitPriceFields']) {
          gformInitPriceFields();
        }
        var current_page = jQuery('#gform_source_page_number_9').val();
        gformInitSpinner( 9, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );
        jQuery(document).trigger('gform_page_loaded', [9, current_page]);
        window['gf_submitting_9'] = false;}
      else if(!is_redirect) {
        var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
        if(!confirmation_content){confirmation_content = contents;}
        setTimeout(function(){
          jQuery('#gform_wrapper_9').replaceWith(confirmation_content);
          jQuery(document).scrollTop(jQuery('#gf_9').offset().top - mt);
          jQuery(document).trigger('gform_confirmation_loaded', [9]);
          window['gf_submitting_9'] = false;
        }, 50);
      } else {
        jQuery('#gform_9').append(contents);
        if(window['gformRedirect']) {gformRedirect();}
      }
      jQuery(document).trigger('gform_post_render', [9, current_page]);
    } );
  } );
  jQuery(document).bind('gform_post_render', function(event, formId, currentPage){if(formId == 9) {} } );
  jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} );
  jQuery(document).ready(function(){jQuery(document).trigger('gform_post_render', [9, 1]) } );
}

if ( jQuery('#gform_wrapper_10').is('div') ) {
  jQuery(document).ready(function($){
    gformInitSpinner( 10, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );

    jQuery('#gform_ajax_frame_10').on('load',function(){
      var contents = jQuery(this).contents().find('*').html();
      var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;if(!is_postback){return;}
      var form_content = jQuery(this).contents().find('#gform_wrapper_10');
      var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_10').length > 0;
      var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
      var is_form = form_content.length > 0 && ! is_redirect && ! is_confirmation;
      var mt = parseInt(jQuery('html').css('margin-top'), 10) + parseInt(jQuery('body').css('margin-top'), 10) + 100;
      if(is_form){jQuery('#gform_wrapper_10').html(form_content.html());
        if(form_content.hasClass('gform_validation_error')){
          jQuery('#gform_wrapper_10').addClass('gform_validation_error');
        } else {
          jQuery('#gform_wrapper_10').removeClass('gform_validation_error');
        }
        setTimeout( function() { /* delay the scroll by 50 milliseconds to fix a bug in chrome */
          jQuery(document).scrollTop(jQuery('#gform_wrapper_10').offset().top - mt);
        }, 50 );
        if(window['gformInitDatepicker']) {
          gformInitDatepicker();
        }
        if (window['gformInitPriceFields']) {
          gformInitPriceFields();
        }
        var current_page = jQuery('#gform_source_page_number_10').val();
        gformInitSpinner( 10, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );
        jQuery(document).trigger('gform_page_loaded', [10, current_page]);
        window['gf_submitting_10'] = false;}
      else if(!is_redirect) {
        var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
        if(!confirmation_content){confirmation_content = contents;}
        setTimeout(function(){
          jQuery('#gform_wrapper_10').replaceWith(confirmation_content);
          jQuery(document).scrollTop(jQuery('#gf_10').offset().top - mt);
          jQuery(document).trigger('gform_confirmation_loaded', [10]);
          window['gf_submitting_10'] = false;
        }, 50);
      } else {
        jQuery('#gform_10').append(contents);
        if(window['gformRedirect']) {gformRedirect();}
      }
      jQuery(document).trigger('gform_post_render', [10, current_page]);
    } );
  } );
  jQuery(document).bind('gform_post_render', function(event, formId, currentPage){if(formId == 10) {} } );
  jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} );
  jQuery(document).ready(function(){jQuery(document).trigger('gform_post_render', [10, 1]) } );
}

if ( jQuery('#gform_wrapper_11').is('div') ) {
  jQuery(document).ready(function($){
    gformInitSpinner( 11, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );

    jQuery('#gform_ajax_frame_11').on('load',function(){
      var contents = jQuery(this).contents().find('*').html();
      var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;if(!is_postback){return;}
      var form_content = jQuery(this).contents().find('#gform_wrapper_11');
      var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_11').length > 0;
      var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
      var is_form = form_content.length > 0 && ! is_redirect && ! is_confirmation;
      var mt = parseInt(jQuery('html').css('margin-top'), 10) + parseInt(jQuery('body').css('margin-top'), 10) + 100;
      if(is_form){jQuery('#gform_wrapper_11').html(form_content.html());
        if(form_content.hasClass('gform_validation_error')){
          jQuery('#gform_wrapper_11').addClass('gform_validation_error');
        } else {
          jQuery('#gform_wrapper_11').removeClass('gform_validation_error');
        }
        setTimeout( function() { /* delay the scroll by 50 milliseconds to fix a bug in chrome */
          jQuery(document).scrollTop(jQuery('#gform_wrapper_11').offset().top - mt);
        }, 50 );
        if(window['gformInitDatepicker']) {
          gformInitDatepicker();
        }
        if (window['gformInitPriceFields']) {
          gformInitPriceFields();
        }
        var current_page = jQuery('#gform_source_page_number_11').val();
        gformInitSpinner( 11, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );
        jQuery(document).trigger('gform_page_loaded', [11, current_page]);
        window['gf_submitting_11'] = false;}
      else if(!is_redirect) {
        var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
        if(!confirmation_content){confirmation_content = contents;}
        setTimeout(function(){
          jQuery('#gform_wrapper_11').replaceWith(confirmation_content);
          jQuery(document).scrollTop(jQuery('#gf_11').offset().top - mt);
          jQuery(document).trigger('gform_confirmation_loaded', [11]);
          window['gf_submitting_11'] = false;
        }, 50);
      } else {
        jQuery('#gform_11').append(contents);
        if(window['gformRedirect']) {gformRedirect();}
      }
      jQuery(document).trigger('gform_post_render', [11, current_page]);
    } );
  } );
  jQuery(document).bind('gform_post_render', function(event, formId, currentPage){if(formId == 11) {} } );
  jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} );
  jQuery(document).ready(function(){jQuery(document).trigger('gform_post_render', [11, 1]) } );
}

if ( jQuery('#gform_wrapper_12').is('div') ) {
  jQuery(document).ready(function($){
    gformInitSpinner( 12, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );

    jQuery('#gform_ajax_frame_12').on('load',function(){
      var contents = jQuery(this).contents().find('*').html();
      var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;if(!is_postback){return;}
      var form_content = jQuery(this).contents().find('#gform_wrapper_12');
      var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_12').length > 0;
      var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
      var is_form = form_content.length > 0 && ! is_redirect && ! is_confirmation;
      var mt = parseInt(jQuery('html').css('margin-top'), 10) + parseInt(jQuery('body').css('margin-top'), 10) + 100;
      if(is_form){jQuery('#gform_wrapper_12').html(form_content.html());
        if(form_content.hasClass('gform_validation_error')){
          jQuery('#gform_wrapper_12').addClass('gform_validation_error');
        } else {
          jQuery('#gform_wrapper_12').removeClass('gform_validation_error');
        }
        setTimeout( function() { /* delay the scroll by 50 milliseconds to fix a bug in chrome */
          jQuery(document).scrollTop(jQuery('#gform_wrapper_12').offset().top - mt);
        }, 50 );
        if(window['gformInitDatepicker']) {
          gformInitDatepicker();
        }
        if (window['gformInitPriceFields']) {
          gformInitPriceFields();
        }
        var current_page = jQuery('#gform_source_page_number_12').val();
        gformInitSpinner( 12, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );
        jQuery(document).trigger('gform_page_loaded', [12, current_page]);
        window['gf_submitting_12'] = false;}
      else if(!is_redirect) {
        var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
        if(!confirmation_content){confirmation_content = contents;}
        setTimeout(function(){
          jQuery('#gform_wrapper_12').replaceWith(confirmation_content);
          jQuery(document).scrollTop(jQuery('#gf_12').offset().top - mt);
          jQuery(document).trigger('gform_confirmation_loaded', [12]);
          window['gf_submitting_12'] = false;
        }, 50);
      } else {
        jQuery('#gform_12').append(contents);
        if(window['gformRedirect']) {gformRedirect();}
      }
      jQuery(document).trigger('gform_post_render', [12, current_page]);
    } );
  } );
  jQuery(document).bind('gform_post_render', function(event, formId, currentPage){if(formId == 12) {} } );
  jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} );
  jQuery(document).ready(function(){jQuery(document).trigger('gform_post_render', [12, 1]) } );
}

if ( jQuery('#gform_wrapper_13').is('div') ) {
  jQuery(document).ready(function($){
    gformInitSpinner( 13, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );

    jQuery('#gform_ajax_frame_13').on('load',function(){
      var contents = jQuery(this).contents().find('*').html();
      var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;if(!is_postback){return;}
      var form_content = jQuery(this).contents().find('#gform_wrapper_13');
      var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_13').length > 0;
      var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
      var is_form = form_content.length > 0 && ! is_redirect && ! is_confirmation;
      var mt = parseInt(jQuery('html').css('margin-top'), 10) + parseInt(jQuery('body').css('margin-top'), 10) + 100;
      if(is_form){jQuery('#gform_wrapper_13').html(form_content.html());
        if(form_content.hasClass('gform_validation_error')){
          jQuery('#gform_wrapper_13').addClass('gform_validation_error');
        } else {
          jQuery('#gform_wrapper_13').removeClass('gform_validation_error');
        }
        setTimeout( function() { /* delay the scroll by 50 milliseconds to fix a bug in chrome */
          jQuery(document).scrollTop(jQuery('#gform_wrapper_13').offset().top - mt);
        }, 50 );
        if(window['gformInitDatepicker']) {
          gformInitDatepicker();
        }
        if (window['gformInitPriceFields']) {
          gformInitPriceFields();
        }
        var current_page = jQuery('#gform_source_page_number_13').val();
        gformInitSpinner( 13, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );
        jQuery(document).trigger('gform_page_loaded', [13, current_page]);
        window['gf_submitting_13'] = false;}
      else if(!is_redirect) {
        var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
        if(!confirmation_content){confirmation_content = contents;}
        setTimeout(function(){
          jQuery('#gform_wrapper_13').replaceWith(confirmation_content);
          jQuery(document).scrollTop(jQuery('#gf_13').offset().top - mt);
          jQuery(document).trigger('gform_confirmation_loaded', [13]);
          window['gf_submitting_13'] = false;
        }, 50);
      } else {
        jQuery('#gform_13').append(contents);
        if(window['gformRedirect']) {gformRedirect();}
      }
      jQuery(document).trigger('gform_post_render', [13, current_page]);
    } );
  } );
  jQuery(document).bind('gform_post_render', function(event, formId, currentPage){if(formId == 13) {} } );
  jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} );
  jQuery(document).ready(function(){jQuery(document).trigger('gform_post_render', [13, 1]) } );
}

if ( jQuery('#gform_wrapper_14').is('div') ) {
  jQuery(document).ready(function($){
    gformInitSpinner( 14, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );

    jQuery('#gform_ajax_frame_14').on('load',function(){
      var contents = jQuery(this).contents().find('*').html();
      var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;if(!is_postback){return;}
      var form_content = jQuery(this).contents().find('#gform_wrapper_14');
      var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_14').length > 0;
      var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
      var is_form = form_content.length > 0 && ! is_redirect && ! is_confirmation;
      var mt = parseInt(jQuery('html').css('margin-top'), 10) + parseInt(jQuery('body').css('margin-top'), 10) + 100;
      if(is_form){jQuery('#gform_wrapper_14').html(form_content.html());
        if(form_content.hasClass('gform_validation_error')){
          jQuery('#gform_wrapper_14').addClass('gform_validation_error');
        } else {
          jQuery('#gform_wrapper_14').removeClass('gform_validation_error');
        }
        setTimeout( function() { /* delay the scroll by 50 milliseconds to fix a bug in chrome */
          jQuery(document).scrollTop(jQuery('#gform_wrapper_14').offset().top - mt);
        }, 50 );
        if(window['gformInitDatepicker']) {
          gformInitDatepicker();
        }
        if (window['gformInitPriceFields']) {
          gformInitPriceFields();
        }
        var current_page = jQuery('#gform_source_page_number_14').val();
        gformInitSpinner( 14, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );
        jQuery(document).trigger('gform_page_loaded', [14, current_page]);
        window['gf_submitting_14'] = false;}
      else if(!is_redirect) {
        var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
        if(!confirmation_content){confirmation_content = contents;}
        setTimeout(function(){
          jQuery('#gform_wrapper_14').replaceWith(confirmation_content);
          jQuery(document).scrollTop(jQuery('#gf_14').offset().top - mt);
          jQuery(document).trigger('gform_confirmation_loaded', [14]);
          window['gf_submitting_14'] = false;
        }, 50);
      } else {
        jQuery('#gform_14').append(contents);
        if(window['gformRedirect']) {gformRedirect();}
      }
      jQuery(document).trigger('gform_post_render', [14, current_page]);
    } );
  } );
  jQuery(document).bind('gform_post_render', function(event, formId, currentPage){if(formId == 14) {} } );
  jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} );
  jQuery(document).ready(function(){jQuery(document).trigger('gform_post_render', [14, 1]) } );
}

if ( jQuery('#gform_wrapper_15').is('div') ) {
  jQuery(document).ready(function($){
    gformInitSpinner( 15, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );

    jQuery('#gform_ajax_frame_15').on('load',function(){
      var contents = jQuery(this).contents().find('*').html();
      var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;if(!is_postback){return;}
      var form_content = jQuery(this).contents().find('#gform_wrapper_15');
      var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_15').length > 0;
      var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
      var is_form = form_content.length > 0 && ! is_redirect && ! is_confirmation;
      var mt = parseInt(jQuery('html').css('margin-top'), 10) + parseInt(jQuery('body').css('margin-top'), 10) + 100;
      if(is_form){jQuery('#gform_wrapper_15').html(form_content.html());
        if(form_content.hasClass('gform_validation_error')){
          jQuery('#gform_wrapper_15').addClass('gform_validation_error');
        } else {
          jQuery('#gform_wrapper_15').removeClass('gform_validation_error');
        }
        setTimeout( function() { /* delay the scroll by 50 milliseconds to fix a bug in chrome */
          jQuery(document).scrollTop(jQuery('#gform_wrapper_15').offset().top - mt);
        }, 50 );
        if(window['gformInitDatepicker']) {
          gformInitDatepicker();
        }
        if (window['gformInitPriceFields']) {
          gformInitPriceFields();
        }
        var current_page = jQuery('#gform_source_page_number_15').val();
        gformInitSpinner( 15, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );
        jQuery(document).trigger('gform_page_loaded', [15, current_page]);
        window['gf_submitting_15'] = false;}
      else if(!is_redirect) {
        var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
        if(!confirmation_content){confirmation_content = contents;}
        setTimeout(function(){
          jQuery('#gform_wrapper_15').replaceWith(confirmation_content);
          jQuery(document).scrollTop(jQuery('#gf_15').offset().top - mt);
          jQuery(document).trigger('gform_confirmation_loaded', [15]);
          window['gf_submitting_15'] = false;
        }, 50);
      } else {
        jQuery('#gform_15').append(contents);
        if(window['gformRedirect']) {gformRedirect();}
      }
      jQuery(document).trigger('gform_post_render', [15, current_page]);
    } );
  } );
  jQuery(document).bind('gform_post_render', function(event, formId, currentPage){if(formId == 15) {} } );
  jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} );
  jQuery(document).ready(function(){jQuery(document).trigger('gform_post_render', [15, 1]) } );
}

if ( jQuery('#gform_wrapper_16').is('div') ) {
  jQuery(document).ready(function($){
    gformInitSpinner( 16, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );

    jQuery('#gform_ajax_frame_16').on('load',function(){
      var contents = jQuery(this).contents().find('*').html();
      var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;if(!is_postback){return;}
      var form_content = jQuery(this).contents().find('#gform_wrapper_16');
      var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_16').length > 0;
      var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
      var is_form = form_content.length > 0 && ! is_redirect && ! is_confirmation;
      var mt = parseInt(jQuery('html').css('margin-top'), 10) + parseInt(jQuery('body').css('margin-top'), 10) + 100;
      if(is_form){jQuery('#gform_wrapper_16').html(form_content.html());
        if(form_content.hasClass('gform_validation_error')){
          jQuery('#gform_wrapper_16').addClass('gform_validation_error');
        } else {
          jQuery('#gform_wrapper_16').removeClass('gform_validation_error');
        }
        setTimeout( function() { /* delay the scroll by 50 milliseconds to fix a bug in chrome */
          jQuery(document).scrollTop(jQuery('#gform_wrapper_16').offset().top - mt);
        }, 50 );
        if(window['gformInitDatepicker']) {
          gformInitDatepicker();
        }
        if (window['gformInitPriceFields']) {
          gformInitPriceFields();
        }
        var current_page = jQuery('#gform_source_page_number_16').val();
        gformInitSpinner( 16, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );
        jQuery(document).trigger('gform_page_loaded', [16, current_page]);
        window['gf_submitting_16'] = false;}
      else if(!is_redirect) {
        var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
        if(!confirmation_content){confirmation_content = contents;}
        setTimeout(function(){
          jQuery('#gform_wrapper_16').replaceWith(confirmation_content);
          jQuery(document).scrollTop(jQuery('#gf_16').offset().top - mt);
          jQuery(document).trigger('gform_confirmation_loaded', [16]);
          window['gf_submitting_16'] = false;
        }, 50);
      } else {
        jQuery('#gform_16').append(contents);
        if(window['gformRedirect']) {gformRedirect();}
      }
      jQuery(document).trigger('gform_post_render', [16, current_page]);
    } );
  } );
  jQuery(document).bind('gform_post_render', function(event, formId, currentPage){if(formId == 16) {} } );
  jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} );
  jQuery(document).ready(function(){jQuery(document).trigger('gform_post_render', [16, 1]) } );
}

if ( jQuery('#gform_wrapper_17').is('div') ) {
  jQuery(document).ready(function($){
    gformInitSpinner( 17, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );

    jQuery('#gform_ajax_frame_17').on('load',function(){
      var contents = jQuery(this).contents().find('*').html();
      var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;if(!is_postback){return;}
      var form_content = jQuery(this).contents().find('#gform_wrapper_17');
      var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_17').length > 0;
      var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
      var is_form = form_content.length > 0 && ! is_redirect && ! is_confirmation;
      var mt = parseInt(jQuery('html').css('margin-top'), 10) + parseInt(jQuery('body').css('margin-top'), 10) + 100;
      if(is_form){jQuery('#gform_wrapper_17').html(form_content.html());
        if(form_content.hasClass('gform_validation_error')){
          jQuery('#gform_wrapper_17').addClass('gform_validation_error');
        } else {
          jQuery('#gform_wrapper_17').removeClass('gform_validation_error');
        }
        setTimeout( function() { /* delay the scroll by 50 milliseconds to fix a bug in chrome */
          jQuery(document).scrollTop(jQuery('#gform_wrapper_17').offset().top - mt);
        }, 50 );
        if(window['gformInitDatepicker']) {
          gformInitDatepicker();
        }
        if (window['gformInitPriceFields']) {
          gformInitPriceFields();
        }
        var current_page = jQuery('#gform_source_page_number_10').val();
        gformInitSpinner( 17, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );
        jQuery(document).trigger('gform_page_loaded', [17, current_page]);
        window['gf_submitting_17'] = false;}
      else if(!is_redirect) {
        var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
        if(!confirmation_content){confirmation_content = contents;}
        setTimeout(function(){
          jQuery('#gform_wrapper_17').replaceWith(confirmation_content);
          jQuery(document).scrollTop(jQuery('#gf_17').offset().top - mt);
          jQuery(document).trigger('gform_confirmation_loaded', [17]);
          window['gf_submitting_17'] = false;
        }, 50);
      } else {
        jQuery('#gform_17').append(contents);
        if(window['gformRedirect']) {gformRedirect();}
      }
      jQuery(document).trigger('gform_post_render', [17, current_page]);
    } );
  } );
  jQuery(document).bind('gform_post_render', function(event, formId, currentPage){if(formId == 17) {} } );
  jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} );
  jQuery(document).ready(function(){jQuery(document).trigger('gform_post_render', [17, 1]) } );
}

if ( jQuery('#gform_wrapper_18').is('div') ) {
  jQuery(document).ready(function($){
    gformInitSpinner( 18, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );

    jQuery('#gform_ajax_frame_18').on('load',function(){
      var contents = jQuery(this).contents().find('*').html();
      var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;if(!is_postback){return;}
      var form_content = jQuery(this).contents().find('#gform_wrapper_18');
      var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_18').length > 0;
      var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
      var is_form = form_content.length > 0 && ! is_redirect && ! is_confirmation;
      var mt = parseInt(jQuery('html').css('margin-top'), 10) + parseInt(jQuery('body').css('margin-top'), 10) + 100;
      if(is_form){jQuery('#gform_wrapper_18').html(form_content.html());
        if(form_content.hasClass('gform_validation_error')){
          jQuery('#gform_wrapper_18').addClass('gform_validation_error');
        } else {
          jQuery('#gform_wrapper_18').removeClass('gform_validation_error');
        }
        setTimeout( function() { /* delay the scroll by 50 milliseconds to fix a bug in chrome */
          jQuery(document).scrollTop(jQuery('#gform_wrapper_18').offset().top - mt);
        }, 50 );
        if(window['gformInitDatepicker']) {
          gformInitDatepicker();
        }
        if (window['gformInitPriceFields']) {
          gformInitPriceFields();
        }
        var current_page = jQuery('#gform_source_page_number_18').val();
        gformInitSpinner( 18, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );
        jQuery(document).trigger('gform_page_loaded', [18, current_page]);
        window['gf_submitting_18'] = false;}
      else if(!is_redirect) {
        var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
        if(!confirmation_content){confirmation_content = contents;}
        setTimeout(function(){
          jQuery('#gform_wrapper_18').replaceWith(confirmation_content);
          jQuery(document).scrollTop(jQuery('#gf_18').offset().top - mt);
          jQuery(document).trigger('gform_confirmation_loaded', [18]);
          window['gf_submitting_18'] = false;
        }, 50);
      } else {
        jQuery('#gform_18').append(contents);
        if(window['gformRedirect']) {gformRedirect();}
      }
      jQuery(document).trigger('gform_post_render', [18, current_page]);
    } );
  } );
  jQuery(document).bind('gform_post_render', function(event, formId, currentPage){if(formId == 18) {} } );
  jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} );
  jQuery(document).ready(function(){jQuery(document).trigger('gform_post_render', [18, 1]) } );
}

if ( jQuery('#gform_wrapper_19').is('div') ) {
  jQuery(document).ready(function($){
    gformInitSpinner( 19, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );

    jQuery('#gform_ajax_frame_19').on('load',function(){
      var contents = jQuery(this).contents().find('*').html();
      var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;if(!is_postback){return;}
      var form_content = jQuery(this).contents().find('#gform_wrapper_19');
      var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_19').length > 0;
      var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
      var is_form = form_content.length > 0 && ! is_redirect && ! is_confirmation;
      var mt = parseInt(jQuery('html').css('margin-top'), 10) + parseInt(jQuery('body').css('margin-top'), 10) + 100;
      if(is_form){jQuery('#gform_wrapper_19').html(form_content.html());
        if(form_content.hasClass('gform_validation_error')){
          jQuery('#gform_wrapper_19').addClass('gform_validation_error');
        } else {
          jQuery('#gform_wrapper_19').removeClass('gform_validation_error');
        }
        setTimeout( function() { /* delay the scroll by 50 milliseconds to fix a bug in chrome */
          jQuery(document).scrollTop(jQuery('#gform_wrapper_19').offset().top - mt);
        }, 50 );
        if(window['gformInitDatepicker']) {
          gformInitDatepicker();
        }
        if (window['gformInitPriceFields']) {
          gformInitPriceFields();
        }
        var current_page = jQuery('#gform_source_page_number_10').val();
        gformInitSpinner( 19, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );
        jQuery(document).trigger('gform_page_loaded', [19, current_page]);
        window['gf_submitting_19'] = false;}
      else if(!is_redirect) {
        var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
        if(!confirmation_content){confirmation_content = contents;}
        setTimeout(function(){
          jQuery('#gform_wrapper_19').replaceWith(confirmation_content);
          jQuery(document).scrollTop(jQuery('#gf_19').offset().top - mt);
          jQuery(document).trigger('gform_confirmation_loaded', [19]);
          window['gf_submitting_19'] = false;
        }, 50);
      } else {
        jQuery('#gform_19').append(contents);
        if(window['gformRedirect']) {gformRedirect();}
      }
      jQuery(document).trigger('gform_post_render', [19, current_page]);
    } );
  } );
  jQuery(document).bind('gform_post_render', function(event, formId, currentPage){if(formId == 19) {} } );
  jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} );
  jQuery(document).ready(function(){jQuery(document).trigger('gform_post_render', [19, 1]) } );
}

if ( jQuery('#gform_wrapper_20').is('div') ) {
  jQuery(document).ready(function($){
    gformInitSpinner( 20, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );

    jQuery('#gform_ajax_frame_20').on('load',function(){
      var contents = jQuery(this).contents().find('*').html();
      var is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;if(!is_postback){return;}
      var form_content = jQuery(this).contents().find('#gform_wrapper_20');
      var is_confirmation = jQuery(this).contents().find('#gform_confirmation_wrapper_20').length > 0;
      var is_redirect = contents.indexOf('gformRedirect(){') >= 0;
      var is_form = form_content.length > 0 && ! is_redirect && ! is_confirmation;
      var mt = parseInt(jQuery('html').css('margin-top'), 10) + parseInt(jQuery('body').css('margin-top'), 10) + 100;
      if(is_form){jQuery('#gform_wrapper_20').html(form_content.html());
        if(form_content.hasClass('gform_validation_error')){
          jQuery('#gform_wrapper_20').addClass('gform_validation_error');
        } else {
          jQuery('#gform_wrapper_20').removeClass('gform_validation_error');
        }
        setTimeout( function() { /* delay the scroll by 50 milliseconds to fix a bug in chrome */
          jQuery(document).scrollTop(jQuery('#gform_wrapper_20').offset().top - mt);
        }, 50 );
        if(window['gformInitDatepicker']) {
          gformInitDatepicker();
        }
        if (window['gformInitPriceFields']) {
          gformInitPriceFields();
        }
        var current_page = jQuery('#gform_source_page_number_20').val();
        gformInitSpinner( 20, 'https://conversation24.com/wp-content/plugins/gravityforms/images/spinner.gif' );
        jQuery(document).trigger('gform_page_loaded', [20, current_page]);
        window['gf_submitting_20'] = false;}
      else if(!is_redirect) {
        var confirmation_content = jQuery(this).contents().find('.GF_AJAX_POSTBACK').html();
        if(!confirmation_content){confirmation_content = contents;}
        setTimeout(function(){
          jQuery('#gform_wrapper_20').replaceWith(confirmation_content);
          jQuery(document).scrollTop(jQuery('#gf_20').offset().top - mt);
          jQuery(document).trigger('gform_confirmation_loaded', [20]);
          window['gf_submitting_20'] = false;
        }, 50);
      } else {
        jQuery('#gform_20').append(contents);
        if(window['gformRedirect']) {gformRedirect();}
      }
      jQuery(document).trigger('gform_post_render', [20, current_page]);
    } );
  } );
  jQuery(document).bind('gform_post_render', function(event, formId, currentPage){if(formId == 20) {} } );
  jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} );
  jQuery(document).ready(function(){jQuery(document).trigger('gform_post_render', [20, 1]) } );
}

// When the user clicks on div, open the popup
function myFunction() {
  var popup = document.getElementById("myPopup");
  popup.classList.toggle("show");
}

// Gravity form WHATS app

function sendTemplate() {
  var CHANNEL_PHONE_NUMBER = '+31633753916'
  var URL = 'https://app.conversation24.com/api/app.php/messaging/v1/wa/send-template'
  var TEMPLATE_ID = 'call_verzoek'
  var TEMPLATE_LANGUAGE = $j('.lang').attr('class').split(' ')[1];
  var ACCOUNT_API_TOKEN = 'AMNhTEQBuTTYDQYtOODaPigGZoaLdtmMuRniVmffvIaJmFZlVk'
  var HEADERS = {
    'Content-Type': 'application/json',
    'X-Api-Token': ACCOUNT_API_TOKEN,
  }

  var isEmpty = function (value) {
    return !value || typeof value !== 'string' || value === '' || value.length === 0
  }


  var sendRequest = function (data) {
    $j.ajax({
      url: URL,
      data: JSON.stringify(data),
      headers: HEADERS,
      type: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      error: function (err) {
        console.error('Unable to send template', err)
      }
    })
  }

  var userPhoneNumber = $j('.iti__selected-dial-code')[0].innerText + $j('.phone_number input').val();
  var userName = $j('.user_name input').val();
  var companyName = $j('.company_name input').val();
  var emailName = $j('.email_name input').val();

  if (isEmpty(userName) || isEmpty(userPhoneNumber) || isEmpty(companyName) || isEmpty(emailName)) {
    return console.error('Inputs must not be empty')
  }

  if (userPhoneNumber[0] !== '+') {
    userPhoneNumber = '+' + userPhoneNumber
  }

  var data = {
    source_phone_number: CHANNEL_PHONE_NUMBER,
    destination_phone_number: userPhoneNumber,
    template_id: TEMPLATE_ID,
    template_language: TEMPLATE_LANGUAGE,
    template_parameters: {
      body: [
        {
          type: 'text',
          text: userName,
        }
      ]
    }
  }

  sendRequest(data)
}

$('#gform_submit_button_25').click(function (e) {
  e.preventDefault()
  sendTemplate()
})
$('#gform_submit_button_26').click(function (e) {
  e.preventDefault()
  sendTemplate()
})
$('#gform_submit_button_27').click(function (e) {
  e.preventDefault()
  sendTemplate()
})
$('#gform_submit_button_28').click(function (e) {
  e.preventDefault()
  sendTemplate()
})
$('#gform_submit_button_29').click(function (e) {
  e.preventDefault()
  sendTemplate()
})